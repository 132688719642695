import { ApolloClient, InMemoryCache, NormalizedCacheObject, from, HttpLink, ApolloLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { Kind } from 'graphql';
import NotificationManager from "components/common/react-notifications/NotificationManager";

// Track retry attempts for each operation
const retryTracker = new Map();

// Create a function to generate a retry link with consistent configuration
const createRetryLink = (isEcoregion = false) => {
  return new RetryLink({
    delay: {
      initial: 1000, // Initial delay in ms
      max: 5000,     // Maximum delay in ms
      jitter: true   // Randomize delay to prevent thundering herd
    },
    attempts: {
      max: isEcoregion ? 5 : 10, // Different max attempts based on client
      retryIf: (error, operation) => {
        // Retry on network errors or server errors (5xx) for both queries and mutations
        const operationType = operation.operationName ? operation.operationName : 'unknown operation';
        const isNetworkError = !error.result && error.networkError;
        const isServerError = error.statusCode >= 500;

        // Safely determine the operation type (query, mutation, subscription)
        let operationKind = 'unknown';
        const definition = operation.query.definitions[0];
        if (definition && definition.kind === Kind.OPERATION_DEFINITION) {
          operationKind = definition.operation;
        }

        // Track retry attempts for this operation
        const operationKey = `${isEcoregion ? 'eco-' : ''}${operation.operationName || 'unknown'}-${Date.now()}`;
        if (!retryTracker.has(operationKey)) {
          retryTracker.set(operationKey, { 
            count: 0, 
            operationName: operation.operationName,
            operationType: operationKind,
            isEcoregion
          });
        }
        
        const trackerInfo = retryTracker.get(operationKey);
        trackerInfo.count += 1;
        retryTracker.set(operationKey, trackerInfo);

        // Log retry attempts
        if (isNetworkError || isServerError) {
          console.log(`Retrying ${isEcoregion ? 'bioregion ' : ''}${operationType} due to error (attempt ${trackerInfo.count}):`, error);
          
          // Only show notification for main client to avoid duplicate notifications
          if (!isEcoregion) {
            NotificationManager.warning(
              `Network problem. Retrying...`,
              null,
              2000,
              null,
              null,
              'filled'
            );
          }
          return true;
        }
        return false;
      }
    }
  });
};

// Create an "afterware" link to handle successful operations
const createAfterwareLink = () => {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      // Check if this operation was retried
      const operationName = operation.operationName || 'unknown';
      
      // Find the tracker entry for this operation
      let trackerEntry = null;
      for (const [key, value] of retryTracker.entries()) {
        if (value.operationName === operationName) {
          trackerEntry = value;
          retryTracker.delete(key); // Clean up the tracker
          break;
        }
      }
      
      // If this operation was retried and now succeeded
      if (trackerEntry && trackerEntry.count > 0) {
        console.log(`${trackerEntry.isEcoregion ? 'Bioregion ' : ''}Operation ${operationName} succeeded after ${trackerEntry.count} retries`);
        
        // Show success notification
        NotificationManager.success(
          'Connection restored!',
          'Success',
          2000,
          null,
          null,
          'filled'
        );
      }
      
      return response;
    });
  });
};

// Create the error link
const errorLink = onError(({ operation, forward }) => {
  return forward(operation);
});

// Create the main Apollo client
const graphqlURL = import.meta.env.VITE_GRAPHQL || 'http://localhost:3000/graphql';
const httpLink = new HttpLink({
  uri: graphqlURL,
  credentials: 'include',
});

export const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([
    errorLink, 
    createAfterwareLink(), 
    createRetryLink(), 
    httpLink
  ]),
  connectToDevTools: true,
  cache: new InMemoryCache({
    typePolicies: {
      EventCourse: {
        fields: {
          agenda: {
            // Somehow Apollo cache also cares about the content stored inside of the agenda field, even thou it is an array stored in the database.
            // This way we tell it to always replace the old content of agenda queried on the same EventCourse.
            // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
            merge: false,
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
    },
    watchQuery: {
      nextFetchPolicy: 'cache-and-network',
    },
  },
});

// Create the ecoregion Apollo client
const bioregionGraphqlURL = import.meta.env.VITE_BIOREGION_GRAPHQL || 'http://localhost:3000/bioregion-graphql';
const bioregionHttpLink = new HttpLink({
  uri: bioregionGraphqlURL,
});

export const ecoregionApolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([
    errorLink, 
    createAfterwareLink(), 
    createRetryLink(true), // Pass true to indicate this is the ecoregion client
    bioregionHttpLink
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
    },
  },
});
